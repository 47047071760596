import React, { useEffect, useState }                                            from 'react'
import theme                                            from '../../theme'
import {createStyles, makeStyles, Theme, ThemeProvider} from '@material-ui/core/styles'
import InstagramIcon                                    from '@material-ui/icons/Instagram'
import YouTubeIcon                                      from '@material-ui/icons/YouTube'
import LinkedInIcon                                     from '@material-ui/icons/LinkedIn'
import TwitterIcon                                      from '@material-ui/icons/Twitter'
import Helmet                                           from 'react-helmet'
import StructuredData                                   from '../StructuredData'
import {level22OrganisationData}                        from '../../structured-data'
import NavMenu                                          from './NavMenu'
import CssBaseline                                      from '@material-ui/core/CssBaseline'
import {Link}                                           from 'gatsby'
import ReactGA                                          from 'react-ga'
import {Container, Grid}                                from '@material-ui/core'
import Typography                                       from '@material-ui/core/Typography'

const useStyles = makeStyles((theme: Theme) => createStyles({
    footerLink: {
        color: '#227E4D',
        textDecoration: 'none'
    },
    wholeWindow: { // Does a 'reset' on the window, removing the default margin
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        backgroundColor: '#3A3737'
    },
    mainContentArea: { // Controls the remaining space where the main content is
        // position: 'relative'
        minHeight: '100vh',
        display: 'grid',
        gridTemplateRows: 'minmax(100vh, auto) auto',
        gridGap: '1rem'
    },
    divider: {
        backgroundColor: '#FFF'
    },
    footerContainer: {
        padding: '3% 0 5% 0',
        backgroundColor: '#222',
        color: '#FFF',
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center'
        }
    },
    footerLinks: {
        textDecoration: 'none',
        color: '#FFF'
    },
    horizontalRule: {
        margin: '20px 0 20px 0',
        borderColor: '#7B7675'
    },
    socialMediaButton: {
        color: '#FFF',
        marginTop: '1rem',
        '&:hover': {
            color: 'goldenrod'
        }
    }
}))

type HelmetData = {
    pageTitle: string
    metaDescription: string
    slug: string
}

type LayoutProps = {
    backgroundColor: string
    omitStructuredData?: boolean
    helmetData: HelmetData
    backgroundImage?: any
    buildTime?: string
}

const Layout: React.FunctionComponent<LayoutProps> = (props) => {
    const classes = useStyles()
    const {backgroundImage, backgroundColor = '#3A3737', children, omitStructuredData, helmetData, buildTime} = props
    const [isClient, setIsClient] = useState(false)

    useEffect(() => {
        setIsClient(true)
    }, [])

    ReactGA.initialize('UA-81020373-1')

    const pageTitle = helmetData ? `${helmetData.pageTitle} | Level 22 Chambers` : 'Level 22 Chambers'
    const metaDescription = helmetData ? helmetData.metaDescription : 'LEVEL 22 CHAMBERS is a leading commercial and administrative set based in the heart of Sydney’s legal precinct'
    const slug = helmetData ? helmetData.slug : ''

    // Handle SSR issue where CSS does not load pre-hydration
    if (!isClient) {
        return (
            <div>
                
            </div>
        )
    }

    return (
        <ThemeProvider theme={theme} key={isClient}>
            <Helmet>
                <title>
                    {pageTitle}
                </title>
                <meta name={'description'} content={metaDescription}/>
                <meta name={'robots'} content={'index, follow'}/>
                <meta property={'og:title'} content={`${pageTitle} | Level 22 Chambers`}/>
                <meta property='og:description' content={metaDescription}/>
                <meta property={'og:site_name'} content={'Level 22 Chambers'}/>
                <meta property={'og:url'} content={`https://level22.com.au/${slug}`}/>
                <meta name={'twitter:title'} content={pageTitle}/>
                <meta name={'twitter:description'} content={metaDescription}/>
                <meta name={'twitter:image'}
                      content={'https://pbs.twimg.com/profile_images/1263290731122135040/6P9J8Kqw_400x400.jpg'}/>
                <meta name='twitter:site' content='@Level22Chambers'/>
                <meta name='twitter:creator' content='@Level22Chambers'/>
                <script async src='https://www.googletagmanager.com/gtag/js?id=UA-81020373-1'/>
                <link rel="canonical" href={`https://level22.com/${helmetData.slug}`}/>
            </Helmet>
            <main style={{background: backgroundColor}} className={classes.wholeWindow}>
                {!omitStructuredData && <StructuredData data={level22OrganisationData}/>}
                <CssBaseline/>
                <NavMenu/>
                <section className={classes.mainContentArea}>
                    <main>
                        {children}
                    </main>
                    <footer className={classes.footerContainer}>
                        <Container maxWidth={'xl'}>
                            <Grid container spacing={3} alignContent="center" justify="center">
                                <Grid item xs={12} sm={4}>
                                    <Typography variant={'body1'}>
                                        <Link className={classes.footerLinks} to="/">
                                            Home
                                        </Link>
                                    </Typography>
                                    <Typography variant={'body1'}>
                                        <Link className={classes.footerLinks} to="/barristers">
                                            Barristers
                                        </Link>
                                    </Typography>
                                    <Typography variant={'body1'}>
                                        <Link className={classes.footerLinks} to="/clerks">
                                            Clerks
                                        </Link>
                                    </Typography>
                                    <Typography variant={'body1'}>
                                        <Link className={classes.footerLinks} to="/practice-areas">
                                            Practice Areas
                                        </Link>
                                    </Typography>
                                    <Typography variant={'body1'}>
                                        <Link className={classes.footerLinks} to="/events">
                                            Events + Seminars
                                        </Link>
                                    </Typography>
                                    <Typography variant={'body1'}>
                                        <Link className={classes.footerLinks} to="/news">
                                            News
                                        </Link>
                                    </Typography>
                                    <Typography variant={'body1'}>
                                        <Link className={classes.footerLinks} to="/readership">
                                            Readership
                                        </Link>
                                    </Typography>
                                    <Typography variant={'body1'}>
                                        <Link className={classes.footerLinks} to="/contact">
                                            Contact
                                        </Link>
                                    </Typography>
                                    <br/>
                                    <Typography variant={'body1'}>
                                        <Link className={classes.footerLinks} to={'/legal'}>Legal &amp; Privacy
                                            Notices</Link><br/>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={4}>

                                    <Typography variant={'body1'} component={'p'}>
                                        LEVEL 22 CHAMBERS<br/>
                                        Level 22<br/>
                                        52 Martin Place<br/>
                                        Sydney NSW 2000
                                    </Typography>
                                    <Typography variant={'body1'} component={'p'}>
                                        T: 02 9151 2222<br/>
                                        E: <a className={classes.footerLinks} href="mailto:clerk@level22.com.au">
                                        clerk@level22.com.au
                                    </a>
                                    </Typography>
                                </Grid>
                                <Grid container justify={'space-around'} spacing={4} item xs={12} sm={4}>
                                    <a target={' _blank'} className={classes.socialMediaButton}
                                       href={' https://twitter.com/level22chambers'}>
                                        <TwitterIcon fontSize={'large'}/>
                                    </a>
                                    <a target={'_blank'} rel={'noreferrer'} className={classes.socialMediaButton}
                                       href={'https://www.linkedin.com/company/level22-chambers'}>
                                        <LinkedInIcon fontSize={'large'}/>
                                    </a>
                                    <a target={'_blank'} rel={'noreferrer'} className={classes.socialMediaButton}
                                       href={'https://www.instagram.com/level22chambers/'}>
                                        <InstagramIcon fontSize={'large'}/>
                                    </a>
                                    <a target={'_blank'} rel={'noreferrer'} className={classes.socialMediaButton}
                                       href={'https://www.youtube.com/channel/UCBYKSSDEqjBB6c2xGiqADSg/videos'}>
                                        <YouTubeIcon fontSize={'large'}/>
                                    </a>
                                </Grid>
                            </Grid>
                            <hr className={classes.horizontalRule}/>
                            <Typography variant={'body1'}>
                                Level 22 Chambers Pty Limited (ABN 52 161 486 022)
                                &copy; {new Date().getFullYear()} - Last updated: {new Date().getTime() / 1000} - Powered
                                by JADE. <a
                                className={classes.footerLink} href={'https://jade.io'}>https://jade.io</a>.
                            </Typography>
                        </Container>
                    </footer>
                </section>
            </main>
        </ThemeProvider>
    )
}

export default Layout

